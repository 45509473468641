<template>
  <div>
    <ul class="sub-navigation pl-0">
      <router-link custom v-slot="{ navigate }" to="/admin/boqs/index">
        <li
          class="eight-of"
          :class="{ active : isBoqs }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">BOQs</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/colours">
        <li
          class="eight-of"
          :class="{ active : isColours }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Colours</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/materials">
        <li
          class="eight-of"
          :class="{ active : isMaterials }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Materials</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/surface-types">
        <li
          class="eight-of"
          :class="{ active : isMaterials }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Surface Types</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/work-types">
        <li
          class="eight-of"
          :class="{ active : isMaterials }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Work Types</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/personnel">
        <li
          v-if="userPermissions.includes(8)"
          class="eight-of"
          :class="{ active : isCrew }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Personnel</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/costs">
        <li
          class="eight-of"
          :class="{ active : isCosts }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Costs</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/fuel/index">
        <li
          class="eight-of"
          :class="{ active : isFuelDeliveries }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Fuel</li>
      </router-link>
    </ul>
    <hr />
    <ul class="sub-navigation pl-0">
      <router-link custom v-slot="{ navigate }" to="/admin/permissions">
        <li
          class="eight-of"
          :class="{ active : isUsers }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Permissions</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/users">
        <li
          class="eight-of"
          :class="{ active : isUsers }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Users</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/boq-bonus-rates/index">
        <li
          class="eight-of"
          :class="{ active : isUsers }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">BOQ Bonus</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/business-types">
        <li
          class="eight-of"
          :class="{ active : isUsers }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Business Types</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/authorisers">
        <li
          class="eight-of"
          :class="{ active : isUsers }"
          @click="navigate"
          @keypress.enter="navigate"
          role="link">Authorisers</li>
      </router-link>
      <router-link custom v-slot="{ navigate }" to="/admin/sales-emails">
        <li
            class="eight-of"
            :class="{ active : isUsers }"
            @click="navigate"
            @keypress.enter="navigate"
            role="link">Sales emails</li>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AdminNav',
  computed: {
    isBoqs() {
      let answer = false;
      if (this.$route.name === 'AdminBoqIndex' || this.$route.name === 'AdminBoqEdit') answer = true;
      return answer;
    },
    isColours() {
      return this.$route.name === 'AdminColours';
    },
    isFuelDeliveries() {
      let answer = false;
      if (
        this.$route.name === 'AdminFuelIndex' || this.$route.name === 'AdminFuelDeliveries'
      ) { answer = true }
      return answer;
    },
    isCosts() {
      let answer = false;
      return answer;
    },
    isUsers() {
      let answer = false;
      return answer;
    },
    isCrew() {
      let answer = false;
      return answer;
    },
    isMaterials() {
      let answer = false;
      return answer;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
};
</script>
