<template>
  <v-row class="no-print pb-3">
    <v-col class="col-3 pt-4 pb-0">
      <router-link to="/">
        <img width="200px" src="../../assets/logo.png" alt="" />
      </router-link>
    </v-col>
    <v-col class="col-9 pb-0">
      <ul class="main-navigation pl-0">
        <li
          v-if="userPermissions.includes(1)"
          :class="{ active : section === 'customers' }"
          @click="goTo('customers')">
          Customers
        </li>
        <li
            v-if="userPermissions.includes(2)"
            :class="{ active : section === 'surveys' }"
            @click="goTo('surveys')">
          Surveys
        </li>
        <li
          v-if="userPermissions.includes(2)"
          :class="{ active : section === 'quotations' }"
          @click="goTo('quotations')">
          Quotations
        </li>
        <li
          v-if="userPermissions.includes(3)"
          :class="{ active : section === 'liveJobs' }"
          @click="goTo('liveJobs')">
          Live Jobs
        </li>
        <li
            v-if="userPermissions.includes(3)"
            :class="{ active : section === 'planning' }"
            @click="goTo('planning')">
          Planning
        </li>
        <li
          v-if="userPermissions.includes(10)"
          :class="{ active : section === 'Drs' }"
          @click="goTo('drs')">
          DRS
        </li>
        <li
          v-if="userPermissions.includes(4)"
          @click="goTo('proformas')">
          Proformas
        </li>
        <li
          v-if="userPermissions.includes(4)"
          @click="goTo('invoices')">
          Invoices
        </li>
        <li
          v-if="userPermissions.includes(9)"
          @click="goTo('invoices')">
          Invoices
        </li>
        <li
          v-if="userPermissions.includes(5)"
          @click="goTo('purchasing')">
          Purchasing
        </li>
        <li
          v-if="userPermissions.includes(6)"
          :class="{ active : section === 'reports' }"
          @click="goTo('reports')">
          Reports
        </li>
        <li
          v-if="userPermissions.includes(7)"
          :class="{ active : section === 'admin' }"
          @click="goTo('admin')">
          Admin
        </li>
        <li @click="logOut()">Log Out</li>
      </ul>
      <quotations-nav v-if="section === 'quotations'" />
      <customers-nav v-if="section === 'customers'" />
      <invoices-nav v-if="section === 'invoices'" />
      <proformas-nav v-if="section === 'proformas'" />
      <purchasing-nav v-if="section === 'purchasing'" />
      <live-jobs-nav v-if="section === 'liveJobs'" />
      <reports-nav v-if="section === 'reports'" />
      <admin-nav v-if="section === 'admin'" />
      <surveys-nav v-if="section === 'surveys'" />
    </v-col>
  </v-row>
</template>

<script>
import QuotationsNav from '@/components/Navigation/QuotationsNav.vue';
import InvoicesNav from '@/components/Navigation/InvoicesNav.vue';
import LiveJobsNav from '@/components/Navigation/LiveJobsNav.vue';
import ProformasNav from '@/components/Navigation/ProformasNav.vue';
import PurchasingNav from '@/components/Navigation/PurchasingNav.vue';
import ReportsNav from '@/components/Navigation/ReportsNav.vue';
import CustomersNav from '@/components/Navigation/CustomersNav.vue';
import AdminNav from '@/components/Navigation/AdminNav.vue';
import SurveysNav from '@/components/Navigation/SurveysNav.vue';
import Localbase from 'localbase';

export default {
  name: 'TopNav',
  components: {
    QuotationsNav,
    InvoicesNav,
    LiveJobsNav,
    ProformasNav,
    PurchasingNav,
    ReportsNav,
    CustomersNav,
    AdminNav,
    SurveysNav,
  },
  computed: {
    section() {
      return this.$store.state.section;
    },
    userPermissions() {
      return this.$store.state.userPermissions;
    },
  },
  methods: {
    goTo(location) {
      this.$store.commit('setSection', location);
      if (location === 'customers') this.$router.push('/customers/index');
      if (location === 'drs') this.$router.push('/drs/index');
      if (location === 'planning') this.$router.push('/planning/plan');
      if (location === 'quotations') this.$router.push('/quotations/index');
      if (location === 'liveJobs') this.$router.push('/live-jobs/index');
      if (location === 'reports') this.$router.push('/reports/index');
      if (location === 'invoices') this.$router.push('/invoices/index');
      if (location === 'proformas') this.$router.push('/proformas/awaiting-index');
      if (location === 'purchasing') this.$router.push('/purchasing/index');
      if (location === 'surveys') this.$router.push('/surveys/index');
      if (location === 'admin') this.$router.push('/admin/index');
    },
    logOut() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.delete().then(() => {
        this.$store.commit('unsetCredentials');
        window.location.replace('/');
      });
    },
  },
};
</script>
