<template>
  <ul class="sub-navigation pl-0">
    <router-link custom v-slot="{ navigate }" to="/surveys/index">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="four-of"
        :class="{ active : isSurveysIndex }">
        All Surveys
      </li>
    </router-link>
    <router-link custom v-slot="{ navigate }" to="/surveys/create">
      <li
        @click="navigate"
        @keypress.enter="navigate"
        role="link"
        class="four-of"
        :class="{ active : isSurveysCreate }">
        Request Survey
    </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'CustomersNav',
  computed: {
    isSurveysIndex() { return this.$route.name === 'SurveysIndex'; },
    isSurveysCreate() { return this.$route.name === 'SurveysCreate'; },
  },
};
</script>
